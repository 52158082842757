import React from 'react'
import './Services.css'
import Tab from './Tab'

const Services = () => {
  return (
    <div id='services'className='serviceSection'>
    <h4 className='serviceHeader'>Creative Visions<br></br>Powered by Tech
</h4>
    <div className='serviceDiv'>
    
<div className='serviceGrid grid'>
<div className='service-we-offer'>
    <p className='services-p-text'>SERVICES</p>
    <Tab />
</div>

</div>
</div>
</div>
  )
}

export default Services