// Intro.js

import React from 'react';
import './Intro.css';
import scrollimg from '../assets/scrolldown.svg';
import { HashLink as Link } from 'react-router-hash-link';

function Intro() {
  return (
    <div id='hero' className='hero-section'>
       <div className='overlay-gradient'></div>
      {/* <div className='ellipse-div'></div>  */}
      <div className='div-block'>
      <div className='background-hero'>
       <div className='hero-contents'> <h1 className='hero-content'>
          Crafting digital<br />
          experiences of <br />
          the <span className='span1'>future.</span>
        </h1>
        <Link to='#services' smooth>
        <img src={scrollimg} alt='' className='scroll-button' />
      </Link></div></div>
      </div>

     
    </div>
  );
}

export default Intro;
