import React, { useEffect } from 'react';
import { Link as ScrollLink, scrollSpy } from 'react-scroll';
import './Agam.css';
import SEO from './SEO'

import sitemap from '../assets/sitemap-agam.png';
import wireframes1 from '../assets/agam-mobil1.png';
import wireframes2 from '../assets/agam-mobil2.png';
import wireframes3 from '../assets/agam-mobil3.png';
import visual from '../assets/visual-agam.png';
import poppins from '../assets/poppins-agam.png';
import mobile1 from '../assets/mobile1-agam.png'
import mobile2 from '../assets/mobile2-agam.png'
import mobile3 from '../assets/mobile3-agam.png'
import mobile4 from '../assets/mobile4-agam.png'
import mobile5 from '../assets/mobile5-agam.png'
import mobile6 from '../assets/mobile6-agam.png'
import AboutImg from '../assets/Agam Edu.png';
import ContactCaseStudy from './ContactCaseStudy';
import Icon from './Icon';
import Previous from './Previous';
import CaseStudyNavbar from './CaseStudyNavbar';
function Agam() {
useEffect(() => {
    // Initialize scrollSpy
    scrollSpy.update();
  }, []);
  
  return (
    <div>
     <SEO
        title="Agam Case Study - Commonsans"
        description="Agam Technologies is an IT company providing learning management and administration systems for private educational institutions."
        keywords="SaaS, mobile design, Agam, UI/UX, case study"
        canonical="https://www.commonsans.studio/agam-case-study"
      />
    <CaseStudyNavbar />
<br></br><br></br>
    <div id="agam">


<div className='main-wrapper'>
<div className='nav-main-div'>
<div className='nav-wrapper'>
<ul id='navbarMenu'>
              <li className='cs-sidenav-link w-inline-block'>
                <ScrollLink
                  className='side-nav'
                  to='abouts-sec'
                  spy={true}
                  smooth="true"
                  duration={500}
                >
                  ABOUT
                </ScrollLink>
              </li>
              <li className='cs-sidenav-link w-inline-block '>
                <ScrollLink
                  className='side-nav'
                  to='discover-sec'
                  spy={true}
                  smooth="true"
                  duration={500}
                >
                  DISCOVER
                </ScrollLink>
              </li>
              <li className='cs-sidenav-link w-inline-block'>
                <ScrollLink
                  className='side-nav'
                  to='ideate-sec'
                  spy={true}
                  smooth="true"
                  duration={500}
                >
                  IDEATE
                </ScrollLink>
              </li>

              <li className='cs-sidenav-link w-inline-block '>
                <ScrollLink
                  className='side-nav'
                  to='wireframe-sec'
                  spy={true}
                  smooth="true"
                  duration={500}
                >
                  WIREFRAME
                </ScrollLink>
              </li>              </ul>

              <ul id='navbarMenu' className='navbarmenu1'>

              <li className='cs-sidenav-link w-inline-block'>
                <ScrollLink
                  className='side-nav'
                  to='visuals'
                  spy={true}
                  smooth="true"
                  duration={500}
                >
                  VISUAL DESIGN
                </ScrollLink>
              </li>
            </ul>
          </div>
        </div>
<div className='wrapper1'>
    <div id='abouts-sec'className="wrapper1">
    <div className="item1">
          <div className='img-title-card1'>
            <img src={AboutImg} alt='' className='discover'/>
          </div>
        </div>
        <div className='ul-section'>
          <ul className='ul-agam'>
            <li>Mobile Design</li>
            <li>SaaS</li>
          </ul>
        </div>
      </div>
      <div id='discover-sec'className="wrapper1">
        <div className="item1">
          <div className='img-titles1-card2'>
            <h4 className='card-title1'>About Agam</h4>
            <p className='card-title-p'>
            Agam Technologies is an IT company that provided learning management and
             administration systems for private educational institutions.    </p>
          </div>
          <div className='img-title-card2'>
            <h4 className='card-title1'>Problem Brief</h4>
            <p className='card-title-p'>
            Agam’s admin director app, exclusively designed for institutional directors,
             struggles to deliver essential information 
            and imposes a high cognitive load on administrators.   </p>
          </div>
        </div>
   </div>
   <div id="problem1-sec">
   <h3 className="problems-p" style={{ whiteSpace: 'nowrap' }}>Problems with the previous design</h3>
   <Icon />
   </div>
      <div id='ideate-sec' className="wrapper1">
        <div className="item1">
        <div className='img-title-card2'>
            <h4 className='card-title1'> Sitemap</h4>
            <p className='card-title-p'>
            We divided the four major features and decided to follow a simple hierarchical navigation scheme.
             If the user wanted to see center-wise view in a city, they could just click on 
             that city to see data of all the centers  </p>
          </div>
          <img src={sitemap} alt='' className='sitemap'/>
        </div>
        </div>
        <div id='wireframe-sec' className="wrapper1">
        <div className="item1">
        <div className='img-title-card2'>
            <h4 className='card-title1'> Wireframes</h4>
            <p className='card-title-p'>
            Over a span of two days, we worked on 13 iterations to brainstorm the best way 
            to present the data, enhance usability for admins,
             and make their daily tasks more efficient and simplified  </p>
          </div>
          <div className='wireframes-mobile'>
          <img src={wireframes1} alt='' className='wireframes'/>
          <img src={wireframes2} alt='' className='wireframes'/>
          <img src={wireframes3} alt='' className='wireframes'/>

          </div>
        </div>
      </div>
      <div id='visual1-sec' className="wrapper1">
        <div className="item1">
        <div className='img-title-card2'>
            <h4 className='card-title1'> Visual Design</h4>
            <p className='card-title-p'>
            The Color Palette was based on the brand colors. We created tones out of
             it to form distinct colors in 
            charts/graphs to ensure easy readability/Visiblity of data  </p>
          </div>
          <img src={visual} alt='' className='visual'/>

          <div  id="visuals"className='visual-div'>
          <img src={poppins} alt='' className='poppins'/>
          <p className='card-title-p poppins-p'>
          
          To ensure readability and legibility for administrators, and versatility to
           create readable charts, Poppins was chosen. The clarity of each character 
           ensures high readability,
           and it offers good legibility at all sizes. </p>
</div>
        </div>
        <div className="item1">
        <h4 id="card-h4" className='card-title1'> Admission Overview</h4>
        <div className='img-title-card3'>
            <p id="card-p"className='card-title-p'>
            Earlier, Admins found it <span className='span-style'>time-consuming</span> to analyze leads, conversions, trends, and <span className='span-style'>gain actionable insights</span>,
             relying on Excel for data extraction<br></br><br></br>
Clear overviews and superimposed area charts, along with duration 
filters, <span className='span-style'>provided quick insights</span> </p>
<div className='mobile-div'>
          <img src={mobile1} alt='' className='mobile1'/>

</div>
          </div>
          <div className='img-title-card3'>
            
<div className='mobile-div1'>
          <img src={mobile2} alt='' className='mobile2'/>

</div>
<div className='chart'>
        <h4 id="card-heading" className='card-title1'>Chart Efficiency</h4>
<p id="charts"className='card-title-p'>
The charts significantly <span className='span-style'>reduced the time</span> needed to create and analyze 
reports by <span className='span-style'>89% </span>and reduced report errors</p></div>
          </div>
         
        </div>
        <div className="item1">
      <div className='lead-insights'> <h4 id="card1-h4" className='card-title1'>Lead Insights</h4>

        <div className='img-title-card4'>
            <p id="card-p3"className='card-title-p'>
            Admins got a <span className='span-style'>quick overview</span> and comparative analysis of leading cities and 
            sources of leads gaining actionable 
            insights quickly that <span className='span-style'>reduced manual effort and time</span> </p>
<div className='mobile-div3'>
          <img src={mobile3} alt='' className='mobile3'/>

</div></div> 
          </div>
          <div className='img-title-card5'>

<div className='mobile-div4'>
          <img src={mobile4} alt='' className='mobile4'/>

</div>
<div className=''>
                    <h4 id="cards-h4" className='card-title1'>Increased lead Conversions</h4>
<p id="cards-p4"className='card-title-p'>
Improvements in analyzing sales performance made it easier to implement changes, leading to an average <span className='span-style'>increase in lead conversion by 50%</span> 
<br></br>
<span className='span-style'>20% increase</span> in sales in <span className='span-style'> targeted cities</span></p>
          </div></div>
         
        </div>
        <div className="item1"><div className='item0'>
        <h4 id="card1-h5" className='card-title1'>Monitor Fee Collection</h4>
        <div className='img-title-card6'>
            <p id="card-p5"className='card-title-p'>
            Earlier, admins struggled to <span className='span-style'> track down</span> unpaid fees, incurring 
            <span className='span-style'>financial losses</span>, which improved 
            <span className='span-style'>fee breakdowns</span> state, center wise
<br></br><br></br>
Admins could <span className='span-style'> quickly identify</span> overdue fees and <span className='span-style'> gain insights </span>
into the cities and center batches with the most overdue, due,
 or paid fees through <span className='span-style'>color-coded presentations</span> </p>
<div className='mobile-div4'>
          <img src={mobile5} alt='' className='mobile5'/>

</div>
          </div></div>
          <div className='img-title-card7'>
            
<div className='mobile-div5'>
          <img src={mobile6} alt='' className='mobile6'/>

</div>
<div className='revenue-recovery'>
<h4 id="card6-h4" className='card-title1'>Revenue Recovery</h4>
<p id="card-p6"className='card-title-p'>
The fee breakdown helped admins easily identify who in the batch had 
overdue fee and <span className='span-style'> notify coordinators </span>for timely action,
 this improvement led to a <span className='span-style'>73% increase</span> in <span className='span-style'>revenue recovery rate</span></p>
          </div></div>
         
        </div>
      </div>
      
      <div className='outcomes'>  <div id="outcomes-agam" className='img-title-cards'>
            <h4 id='h4-outcomes' className='card-title1'> Outcomes</h4>
            <p id='p-outcomes' className='card-title-p'>
            Through the implemented changes, admins were easily able to obtain required data and actionable insights accurately, leading to a significant improvement in lead conversions. 
<br></br><br></br>
Managing admissions and fee collection became convenient with the easy breakdown by city, state, and center, enhancing navigation and oversight</p>
          </div>
          </div>
          <Previous 
        prevLink="/walkfin-admin-case-study" 
        prevText="Walkfin Admin " 
        nextLink="/walkfin-user-case-study" 
        nextText="Walkfin User" 
      />
      
      </div>
      
      </div>
      <div className="embedded-contact-form">
        <ContactCaseStudy />
      </div>
      </div></div>
  );
}

export default Agam;