import React, { Suspense, lazy } from 'react';
import './ContactUs.css';
import { Modal } from 'react-bootstrap';

const LazyContactForm = lazy(() => import('./ContactForm'));

function ContactUs() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <button id='navButton' className='navDesktopMenuBtn1' onClick={handleShow}>
        Contact Us
      </button>
      <Modal show={show} onHide={handleClose}>
        <Suspense fallback={<div>Loading...</div>}>
          <LazyContactForm />
        </Suspense>
      </Modal>
    </div>
  );
}

export default ContactUs;
