/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import Case from '../assets/case1.gif'
import './Stories.css'
export default function Stories() {
  return (
   
    <div id='about-us'className="stories-case">
    <div className='stories-img-div'>
      <div id='stories1'>
        <h2 id='stories-heading'className="stories-heading"><span className='helping'>Helping businesses build </span> scalable products</h2>
        <p className='p-stories'>Commonsans, is on a mission to revolutionize the future of digital experiences. Here technology is seen as an advantage rather than a limitation.<br></br><br></br>
        To craft innovative solutions, the full spectrum of technological possibilities is explored—whether it's AR, VR, or beyond. With designers from diverse domains and expertise eager to tackle any challenge presented</p>
      </div>
      <div id='stories2' className='stories-img-holder'>
        <img id='stories3'src={Case} alt="GIF Image" className="imgcase" />
        <div className='bgvideo w-background-video w-background-video-atom'>
        <video
      id="f4afe733-cd3c-37d6-fefc-c6833f1d3ed4-video"
      autoPlay
      loop
      style={{
        backgroundImage: "url('../assets/case1.gif')",
      }}
      muted
      playsInline
      data-wf-ignore="true"
      data-object-fit="cover"
    >
      <source src="https://assets-global.website-files.com/62be93c79c2088b85951e130/63315efc0641793fa0984c68_0001-0200-transcode.mp4" data-wf-ignore="true" />
      <source src="https://assets-global.website-files.com/62be93c79c2088b85951e130/63315efc0641793fa0984c68_0001-0200-transcode.webm" data-wf-ignore="true" />
    </video>
        </div>
      </div>
      </div>
      <div class="ellipse-div stories"></div>
    </div>
  );
}