import React from 'react'
import './Client.css'
import img1 from '../assets/icons/Designerrs.svg'
import img2 from '../assets/icons/ABCD.svg'
import img3 from '../assets/icons/agamedu.svg'
import img4 from '../assets/icons/Walkfin.svg'
import img5 from '../assets/icons/Wission.svg'
import img6 from '../assets/icons/Designerrs.svg'
import img7 from '../assets/icons/ABCD.svg'
import img8 from '../assets/icons/agamedu.svg'
import img9 from '../assets/icons/Walkfin.svg'
import img10 from '../assets/icons/Wission.svg'
import Marquee from 'react-fast-marquee'

function Client() {
  return (
    <div id='case-studies' className='marquee-section'>
    <div className='client-tag-heading'>
        <h4 className='heading-text'>
        Our Clients  
        </h4>
    </div>
    <div>
        <Marquee direction="left" speed={100} delay={5}>
          <div className="image_wrapper">
            <img src={img1} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img2} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img3} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img4} alt="" />
          </div>
          <div>
            <img src={img5} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img6} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img7} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img8} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img9} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img10} alt="" />
          </div>
        </Marquee>
      </div>
    </div>



  )
}

export default Client