import React, { useState, useEffect } from 'react';
import './CaseStudyNavbar.css';
import arrows from '../assets/arrows.svg';
import { Modal } from 'react-bootstrap';
import ContactForm from './ContactForm';
import { HashLink as Link } from 'react-router-hash-link';

const CaseStudyNavbar = () => {
  const [clicked, setClicked] = useState(false);
  const [show, setShow] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleClose = () => {
    setClicked(false);
    setShow(false);
  };

  const handleShow = () => setShow(true);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='case-study-sticky-nav'>
      <nav className='case-study-navbarItems'>
        {isMobile ? (
          <>
            <Link to="/" className='case-study-back-to-home-btn'>
              <img src={arrows} alt='Back to Home' />
              Back to Home
            </Link>
            <div className='case-study-menu-icons' onClick={handleClick}>
              <i className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
            </div>
            <ul id='case-study-navbarMenu' className={clicked ? 'case-study-nav-menu active1' : 'case-study-nav-menu'}>
              <li className='case-study-nav-links'>
                <Link className='active1'to='/' smooth="true" onClick={handleClick}>
                  Home
                </Link>
              </li>
              <li className='case-study-nav-links'>
                <Link to='/#services' smooth="true" onClick={handleClick}>
                  Services
                </Link>
              </li>
              <li className='case-study-nav-links'>
                <Link to='/#case-studies' smooth="true" onClick={handleClick}>
                  Case Studies
                </Link>
              </li>
              <li className='case-study-nav-links'>
                <Link to='/#about-us' smooth="true" onClick={handleClick}>
                  About
                </Link>
              </li>
              <li className='case-study-nav-links privacy'>
                <Link to='/privacy-policy' smooth="true" onClick={handleClick}>
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </>
        ) : (
          <>
            <Link to="/" className='case-study-back-to-home-btn'>
              <img src={arrows} alt='Back to Home' />
              Back to Home
            </Link>
            <Link to='#contact-form'><button className='case-study-contact-us-btn-nav' onClick={handleShow}>
              Contact Us
            </button></Link>
          </>
        )}
        <Modal show={show} onHide={handleClose}>
          <ContactForm />
        </Modal>
      </nav>
    </div>
  );
};

export default CaseStudyNavbar;