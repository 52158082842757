import React, { useEffect } from 'react';
import { Link as ScrollLink, scrollSpy } from 'react-scroll';
import Walkfinimg1 from '../assets/walkfin-user.png';
import './ContactCaseStudy.css'
import './WalkfinUser.css'
import color1 from '../assets/color1-user.png'
import color2 from '../assets/color2.png'
import color3 from '../assets/color3-user.png'
import SEO from './SEO'

import roboto from '../assets/roboto-user.png'
import walkfinuser1 from '../assets/walkfinuser-mobile1.png'
import walkfinuser2 from '../assets/walkfinuser-mobile2.png'
import walkfinuser3 from '../assets/walkfinuser-mobile3.png'

import mb1 from '../assets/mockup1-user.png'
import mb2 from '../assets/mockup2-user.png'
import mb3 from '../assets/mockup3-user.png'
import mb5 from '../assets/mockup5-user.png'
import mb6 from '../assets/mockup6-user.png'
import mb7 from '../assets/mockup7-user.png'
import Previous from './Previous';

import ContactCaseStudy from './ContactCaseStudy';
import CaseStudyNavbar from './CaseStudyNavbar';
function WalkfinUser() {
  useEffect(() => {
    // Initialize scrollSpy
    scrollSpy.update();
  }, []);
  
  return (
    <div>
            <SEO
        title="Walkfin User Case Study - Commonsans"
        description="Walkfin is a new-age fintech company focused on providing stability, freedom, and growth opportunities through tailored lending options to MSME entrepreneurs and students."
        keywords="fintech, mobile design, Walkfin, UI/UX, case study"
        canonical="https://www.commonsans.studio/walkfin-user-case-study"
      />

      <CaseStudyNavbar />
      <br></br><br></br>
    <div id="walkfin-section">
 
    <div className='main-wrapper1'>
      <div className='nav-main-div'>
        <div className='nav-wrapper'>
          <ul id='navbarMenu'>
            <li className='cs-sidenav-link w-inline-block'>
              <ScrollLink
                className='side-nav'
                to='about-sec'
                spy={true}
                smooth="true"
                duration={500}
              >
                ABOUT
              </ScrollLink>
            </li>
            <li className='cs-sidenav-link w-inline-block '>
              <ScrollLink
                className='side-nav'
                to='problem-sec'
                spy={true}
                smooth="true"
                duration={500}
              >
                DISCOVER
              </ScrollLink>
            </li>
            <li className='cs-sidenav-link w-inline-block'>
              <ScrollLink
                className='side-nav'
                to='visual-sec'
                spy={true}
                smooth="true"
                duration={500}
              >
                IDEATE
              </ScrollLink>
            </li>
            <li className='cs-sidenav-link w-inline-block '>
              <ScrollLink
                className='side-nav'
                to='wireframe-sec'
                spy={true}
                smooth="true"
                duration={500}
              >
                WIREFRAME
              </ScrollLink>
            </li>
            <li className='cs-sidenav-link w-inline-block'>
              <ScrollLink
                className='side-nav'
                to='efficiency-sec'
                spy={true}
                smooth="true"
                duration={500}
              >
                VISUAL DESIGN
              </ScrollLink>
            </li>
          </ul>
        </div>
      </div>
      <div className='wrapper2'>
  <div id='about-sec'className="wrapper2">
  <div className="items1">
        <div className='img-titles1-card1'>
          <img src={Walkfinimg1} alt='' className='walkfin1'/>
        </div>
      </div>
      <div className='ul-section'>
        <ul className='ul-walkfin'>
          <li>Mobile Design</li>
          <li>Fin tech</li>
        </ul>
      </div>
    </div>
    <div id='problem-sec'className="wrapper2">
      <div className="items1">
        <div className='img-titles-card1'>
          <h4 className='card-titles1 about'>About Walkfin</h4>
          <p className='card-titles-p'>
          Walkfin is a new-age fintech company focused on
           providing stability, freedom, and growth opportunities 
           through tailored 
          lending options to MSME entrepreneurs and students.
              </p>
        </div>
        <div className='img-titles-card1'>
          <h4 className='card-titles1'>Problem Brief</h4>
          <p className='card-titles-p'>
          Walkfin aimed to digitize the loan application process 
          for users to address key issues<br></br><br></br>
          <span className='span-style'>Save time</span> by making the process streamlined and convenient<br></br><br></br>
          Enable <span className='span-style'> users</span> to understand loan options, apply, 
          and upload documents<span className='span-style'>independently</span> 
              </p>
        </div>

      </div>
 </div>
    <div id='visual-sec' className="wrapper2">
      <div className="items1">
        <div className='img-titles-card4'>
        <h4 className='card-titles1'>Visual Design</h4>
          <p className='card-titles-p'>
          The application's color scheme is derived from the brand's vision to convey 
          financial stability, freedom, growth, and independence.
</p>
        </div>
        <div className='color'>
        <img src={color1} alt='' className='color1'/>
        <img src={color2} alt='' className='color2'/>
        <img src={color3} alt='' className='color3'/>
        </div>
      </div>
    </div>
    <div id='roboto-sec' className="wrapper2">
      <div className="item1">
        <div className='img-titles-card-roboto'>
         
        <img src={roboto} alt='' className='roboto'/>
        <p className='card-titles1-p1'>
        Roboto's clean and geometric lines improve readability,
         making text easier to read on various screen sizes
</p>
        </div>
      </div>
    </div>

    <div id='wireframe-sec' className="wrapper2">
    <div className="items1">
        <div className='img-titles-card-wireframe'>
        <h4 className='card-titles-wireframe'>Wireframe</h4>

          <p className='card-titles-wireframe'>
          We identified the key requirements and started wireframing with the goal
           of making the loan application process easy and understandable for users, 
           to enable them
           to complete the process independently without any admin support.
</p>
        </div>
        <div className='walkfinuser-container'
>       <img src={walkfinuser1} alt='' className='wireframe'/>
  <img src={walkfinuser2} alt='' className='wireframe'/>
  <img src={walkfinuser3} alt='' className='wireframe'/>
</div> 
      </div>
    </div>
    <div id='efficiency-sec' className="wrapper2">
    <div className="items1">
      <h4 className='card-titles1-pc1'>Home & Dashboard</h4>
        <div className='img-titles-card-pc1'>
          <p className='card-titles-pc1'>
          <span className='span-style'>Customized views</span> for different stages provided clear overviews, 
          making it easy for users to gain 
          insight into their loan status and next steps, even for multiple loans.
</p>
        <img src={mb1} alt='' className='mb1'/>

        </div>
      </div>
    </div>
    <div id='enhanced-sec' className="wrapper2">
    <div className="items1">
        <div className='img-titles-card-pc2'>
        <img src={mb2} alt='' className='mb2'/>
      <div>  <h4 className='card-titles1-pc1'>Loan Overview</h4>
          <p className='card-titles-pc2'>
          The overview helped users gain insight into the <span className='span-style'> status </span>of 
          their <span className='span-style'>loan 
          applications</span> and provided <span className='span-style'>information</span>  on their
          <span className='span-style'>current loans.</span> 
</p></div>

        </div>
      </div>
    </div>
    <div id='stream-sec' className="wrapper2">
    <div className="items1">
      <h4 className='card-titles1-stream'>User-Friendly Processes</h4>
        <div className='img-titles-card-stream'>
          <p className='card-titles-stream'>
          <span className='span-style'>Clear breakdowns</span> and <span className='span-style'>defined processes </span> 
          increased transparency, 
          simplifying the entire process of 
          eligibility checks and applying for loans digitally, making it 
          <span className='span-style'>understandable</span> and <span className='span-style'>user-friendly</span>
</p>
        <img src={mb3} alt='' className='mb3'/>

        </div>
        <div className='img-titles-card-stream1'>
        <img src={mb5} alt='' className='mb4'/>
      <div> <h4 className='streamlined-container'>Streamlined Process </h4>

          <p className='card-titles-stream1'>
          The <span className='span-style'>average time </span>to apply for  a loan
           application reduced by<span className='span-style'> 64%</span>
<br></br><br></br>
The <span className='span-style'>error </span>in loan applications and
 document submissions decreased by <span className='span-style'>31%</span>
</p></div> 

        </div>
      </div>
    </div>
    
 <div id='enhance-sec' className="wrapper2">
    <div className="items1">
      <h4 className='card-titles1-enhance'>Enhancing Efficiency</h4>
        <div className='img-titles-card-enhance'>
          <p className='card-titles-enhance'>
          <span className='span-style'>Clear error messages </span> were meticulously crafted to pinpoint 
          the<span className='span-style'>exact location of errors,</span>  greatly enhancing the user experience. 
<br></br><br></br>
Additionally,<span className='span-style'> notifications regarding delayed payments</span> and any
 additional payments were implemented
</p>
        <img src={mb6} alt='' className='mb6'/>

        </div>
        <div className='img-titles-card-enhance1'>
        <img src={mb7} alt='' className='mb7'/>
       <div><h4 className='card-titles2-enhance'>Error Message States</h4>

          <p className='card-titles-enhance1'>
          The error message enhanced <span className='span-style'> self-reliance</span> in the loan process and document submission 
          resulted in a <span className='span-style'>56% decrease</span> in <span className='span-style'>administrative costs.</span>
</p></div> 

        </div>
      </div>
    </div>
    <div id='achieved1-sec' className="wrapper2">
    <div className="items1">
    <div className='img-titles-card-achieved1'>
      <h4 className='card-titles1-achieved1'>What we achieved </h4>
          <p className='card-titles-achieved1'>
          Through the app, we streamlined the online loan application process,
           reducing administrative interference, thus simplifying the process 
          for users while simultaneously cutting operational costs.
</p>

        </div>
        
      </div>
    </div>
    <Previous 
      prevLink="/agam-case-study" 
      prevText="Agam Edu " 
      nextLink="/walkfin-admin-case-study" 
      nextText="Walkfin Admin" 
    />
    </div>      
    </div>
    <div className="embedded-contact-form">
    <ContactCaseStudy />
    </div>
  </div></div>
    )
}

export default WalkfinUser