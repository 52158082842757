import React, { useState } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../components/ContactForm.css';
import check from '../assets/checkcircle.svg';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    subject: '',
    honey: '' // honeypot field
  });

  const [showNewMessage, setShowNewMessage] = useState(false);
  const [isSpam, setIsSpam] = useState(false);

  const handleClose = () => {
    setShowNewMessage(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if honeypot field is filled (which means spam)
    if (formData.honey !== '') {
      setIsSpam(true);
      return;
    }

    try {
      const response = await axios.post(
        'https://api.airtable.com/v0/appCouJkzzZs8C8rr/Leads',
        {
          fields: {
            Name: formData.name,
            "Email Address": formData.email,
            "Phone Number": formData.phone,
            Company: formData.company,
            Query: formData.subject,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Record created:', response.data);

      setFormData({ name: '', phone: '', email: '', company: '', subject: '', honey: '' });

      // Show success message
      setShowNewMessage(true);

      // Hide the success message after 3 seconds
      setTimeout(() => {
        setShowNewMessage(false);
      }, 3000);
    } catch (error) {
      console.error('Error creating record:', error.response.data);
    }
  };

  return (
    <>
      <div id='contact-form' className='contact-form'>
        <div className='form-holder'>
          <div className='form-content'>
            {showNewMessage && (
              <div className="box">
                <div className="group">
                  <div className="contact-overlay">
                    <div className="frame">
                      <div className="div">
                        <img className="check-circle" alt="Check circle" src={check} />
                        <div className="text-wrapper">We got your Message!</div>
                      </div>
                      <p className="p">Thank you for reaching us! We’ll get in touch within 1-2 working days.</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isSpam && (
              <div className="box">
                <div className="group">
                  <div className="contact-overlay">
                    <div className="frame">
                      <div className="div">
                        <img className="check-circle" alt="Check circle" src={check} />
                        <div className="text-wrapper">Potential spam detected!</div>
                      </div>
                      <p className="p">Your message seems to contain spam content. Please try again.</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!showNewMessage && !isSpam && (
              <h2 className='contact-heading'>
                Let's Create Something <br />brilliant!
              </h2>
            )}
          </div>
          <div
            id='form1'
            className={`form-block form ${showNewMessage ? 'hidden' : ''}`}
          >
            <Button
              variant='link'
              href='/'
              className='close-btn'
              onClick={handleClose}
            >
              <span aria-hidden='true'>&times;</span>
            </Button>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  type='text'
                  placeholder=''
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                  className='w-input'
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Company Name*</Form.Label>
                <Form.Control
                  type='text'
                  placeholder=''
                  name='company'
                  value={formData.company}
                  onChange={handleChange}
                  className='w-input'
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Email address*</Form.Label>
                <Form.Control
                  type='email'
                  placeholder=''
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  className='w-input'
                  required
                />
              </Form.Group>
              
              <Form.Group>
                <Form.Label>Phone Number*</Form.Label>
                <Form.Control
                  type='tel'
                  maxLength={256}
                  placeholder=''
                  name='phone'
                  value={formData.phone}
                  onChange={handleChange}
                  className='w-input'
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as='textarea'
                  placeholder=''
                  name='subject'
                  value={formData.subject}
                  onChange={handleChange}
                  className='w-input'
                  required
                />
              </Form.Group>

              {/* Honeypot field */}
              <div style={{ position: 'absolute', left: '-9999px' }}>
                <label htmlFor="honey">Leave this empty</label>
                <input
                  type="text"
                  name="honey"
                  value={formData.honey}
                  onChange={handleChange}
                />
              </div>

              <Button type='submit' className='btn'>Submit</Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;

