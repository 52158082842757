import React, { useEffect } from 'react';
import { Link as ScrollLink, scrollSpy } from 'react-scroll';
import { Link } from 'react-router-dom';
import './Agam.css';
import discover from '../assets/images/wissionimg2.png';
import ideate from '../assets/images/wissionimg3.png';
import wireframe from '../assets/images/wissionimg4.png';
import visual from '../assets/images/wissionimg5.png';
import leads from '../assets/images/wissionimg7.png';
import admission from '../assets/images/wissionimg6.png';
import arrows from '../assets/arrows.svg';
import WissionImg from '../assets/images/wissionimg1.png';

function Wission() {
  useEffect(() => {
    // Initialize scrollSpy
    scrollSpy.update();
  }, []);

  return (
    <div id="wission-section">
      <div className='sticky-nav-back-to-home1 w-nav1'>
      <div className='nav-div1'>
        <Link to='/#services' className='brand w-nav-brand' aria-label='home' smooth="true">
            <img src={arrows} alt='' className=''/>
            <h5 className='back-to-home1'>Back to Home</h5>
        </Link>
        <div className='nav-button'>
            <div className='w-icon-nav-menu'></div>
        </div>
        <a href='/' className='contact-us-btn-nav w-button'>Contact Us</a>
    </div>
    <div className='divider-div-nav'></div>
    <div className='w-nav-overlay'></div>


      </div>
      <div className='main-wrapper'>
        <div className='nav-main-div'>
          <div className='nav-wrapper'>
          <ul id='navbarMenu'>
              <li className='cs-sidenav-link w-inline-block'>
                <ScrollLink
                  className='side-nav'
                  to='about-sec'
                  spy={true}
                  smooth="true"
                  duration={500}
                >
                  ABOUT
                </ScrollLink>
              </li>
              <li className='cs-sidenav-link w-inline-block '>
                <ScrollLink
                  className='side-nav'
                  to='discover-sec'
                  spy={true}
                  smooth="true"
                  duration={500}
                >
                  DISCOVER
                </ScrollLink>
              </li>
              <li className='cs-sidenav-link w-inline-block'>
                <ScrollLink
                  className='side-nav'
                  to='ideate-sec'
                  spy={true}
                  smooth="true"
                  duration={500}
                >
                  IDEATE
                </ScrollLink>
              </li>

              <li className='cs-sidenav-link w-inline-block '>
                <ScrollLink
                  className='side-nav'
                  to='wireframe-sec'
                  spy={true}
                  smooth="true"
                  duration={500}
                >
                  WIREFRAME
                </ScrollLink>
              </li>              </ul>

              <ul id='navbarMenu' className='navbarmenu1'>

              <li className='cs-sidenav-link w-inline-block'>
                <ScrollLink
                  className='side-nav'
                  to='visual-sec'
                  spy={true}
                  smooth="true"
                  duration={500}
                >
                  VISUAL DESIGN
                </ScrollLink>
              </li>
            </ul>
          </div>
        </div>
        <div className='wrapper1'>
    <div id='about-sec'className="wrapper1">
    <div className="item1">
          <div className='img-title-card1'>
            <img src={WissionImg} alt='' className='wission'/>
          </div>
        </div>
      </div>
      <div id='discover-sec'className="wrapper1">
        <div className="item1">
          <div className='img-title-card1'>
            <h4 className='card-title1'>Problem Brief</h4>
            <p className='card-title-p'>
            The ultimate goal of the application is to connect the artists 
            and fans deeply. We attempted to achieve this through a
             well-defined content feed for the audience according to 
             their interests. We also sought to increase the chances for artists and fans to directly interact, engage and strengthen their bond.          </p>
            <img src={discover} alt='' className='discover'/>
          </div>
        </div>
   </div>
      <div id='ideate-sec' className="wrapper1">
        <div className="item1">
          <div className='img-title-card1'>
            <p className='card-title-p'>
            “We started with Ideation, generated ideas from best to worst possible ways, and had brainstorming sessions to formulate ideas for ideal solutions. We defined the features to create an effective and streamlined user flow before starting the wireframes.”

</p>
            <img src={ideate} alt='' className='ideate'/>
          </div>
        </div>
      </div>
      <div id='wireframe-sec' className="wrapper1">
        <div className="item1">
          <div className='img-title-card1'>
            <h4 className='card-title1'>Wireframe</h4>
            <img src={wireframe} alt='' className='wireframe'/>
          </div>
        </div>
      </div>
 
      <div id='visual-sec' className="wrapper1">
        <div className="item1">
          <div className='img-title-card1'>
            <h4 className='card-title1'>Visual Design</h4>
            <p className='card-title-p'>
            The Color Palette was based on the brand colors. We created tones out of
             it to form distinct colors in charts. We also created the
              tones to ensure accessibility for almost all types of color blindness. 
              We used Poppins because of its clean, stylish, and friendly vibe. And 
              its wide range of font styles and open feel improves readability and style.
</p>
            <img src={visual} alt='' className='visual'/>
            
<h4 className='card-title1'>Admission Overview</h4>
<div className='grid-admission'>
            <p className='card-title-p'>
            Admissions Overview indicates a city’s performance by 
            showing a relative scale in descending order. An area chart 
            shows the fluctuations of total admissions over time for the 
            city selected. The card also shows the % increase/decrease
             depending on the date range selected.
</p>
            <img src={admission} alt='' className='admission'/>
</div>
<h4 className='card-title1'>Get a sense of all potential leads</h4>
<div className='grid-admission2'>
            <p className='card-title-p'>
            Through Enquiries Overview, users can get an idea
             about the quality of leads generated and the performance
              of the sales team.
</p>
            <img src={leads} alt='' className='leads'/>

</div>
          </div>
        </div>
      </div>
      </div>      
      </div>
    </div>
  );
}

export default Wission;
