import React from 'react';
import previous from '../assets/arrow-agam.png';
import { HashLink as Link } from 'react-router-hash-link';
import next from '../assets/arrow1-agam.png';
import './Previous.css';

function Previous({ prevLink, prevText, nextLink, nextText }) {
  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className='previous-next'>
      <div className='previous-container'>
        <Link to={prevLink} onClick={handleScrollToTop}>
          <img src={previous} alt='Previous' className='previous' />
        </Link>
        <div className='walkfins'>
          <p>Previous</p>
          <Link to={prevLink} className='footer-text-link' onClick={handleScrollToTop}>
            {prevText}
          </Link>
        </div>
      </div>
      <div className='next-container'>
        <Link to={nextLink} onClick={handleScrollToTop}>
          <img src={next} alt='Next' className='next' />
        </Link>
        <div className='walkfins-admin'>
          <p>Next</p>
          <Link to={nextLink} className='footer-text-link' onClick={handleScrollToTop}>
            {nextText}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Previous;
