// Home.js
import React from "react";
import Navbar from "./Navbar";
import Intro from "./Intro";
import Services from "./Services";
import Client from "./Client";
import Carousel from "./Carousel";
import Stories from "./Stories";
import Mission from "./Mission";
import Testimonials from "../Testimonials";
import Footer from "./Footer";
import ContactUs from "./ContactUs";

const Home = () => {
  return (
    <>
      <Navbar />
      <br></br>
      <Intro />
      <Services />
      <Client />
      <Carousel />
      <Stories />
      <Mission />
      <Testimonials />
      <Footer />
      <br></br>
      <ContactUs />
    </>
  );
};

export default Home;
