
// import { text } from 'body-parser';
import './components/Testimonial.css'
import { useState } from 'react';

// this array holds the data for the carousel
const images = [
  {
    id: 1,
    text: '“I wanted to build a platform, which grows user’s interest in areas like music, arts. I wanted to build a high fidelity prototype for testing to validate the concept. Thanks for helped me with your expertise in UX UI Design and to deliver all assets required in a short duration.”',
    author: 'Sudip Chakraborty',
    role: 'CEO, Wission',
   
  },
  {
    id: 2,
    text: '“Team commonsans have done a commendable job in designing our application. The design and research was done by your team in such a way that we were not able to find any major changes to make. And whatever was requested was promptly taken care of.”',
    author: 'Padmesh tipathi',
    role: 'Chief Technology Officer at Walkfin',
  },
  {
    id: 3,
    text:'“Commonsans exceeded our expectations in every way. Their unique design solutions were perfectly aligned with our brands vision. The teams professionalism and dedication ensured a smooth workflow and outstanding results”',
    author: 'Rahul Kothari',
    role: 'Product Manager, Agam',
  },
  {
    id: 4,
    text:'“Our experience with common sans was fantastic. They brought fresh, innovative ideas to the table and executed them flawlessly. Their attention to detail and commitment to excellence were evident in every aspect of the project. We are delighted with the results and look forward to future collaborations.”',
    author: 'Chandan Singh',
    role: 'Head of VR,Smart VIizX',
  },
];

function Testimonials() {
  // show the photo with this index
  const [currentIndex, setCurrentIndex] = useState(0);

  
  return (
    <>
      {/* Render the carousel */}
      <div className='testimonials-section'>
      <div className='testimonial-div'>
      <div class="testimonial-heading-div">
      <img src="https://assets-global.website-files.com/62be93c79c2088b85951e130/62c3d486be46ba91cb7d2e19_testimonial%20vector.svg" loading="lazy" alt="" class="testimonial-vector" />
      <h4 class="testimonial-heading">Don’t just take our word for it</h4>
      <img src="https://assets-global.website-files.com/62be93c79c2088b85951e130/62c3d4c559bbeedbd63c4e56_testimonial%20vector%20right.svg" loading="lazy" alt="" class="testimonial-vector" />

      </div>
      <div className='slider-container1'>
        {images.map((image) => (
          <div
            key={image.id}

            // if the photo is the current photo, show it
            className={
              images[currentIndex].id === image.id ? 'fade' : 'slide fade'
            }
          >
          <div className="testimonial-content">
              <p className='testimonials'>{image.text}</p>
              <div className="testimonial-author">
                  <h4 className='author'>{image.author}</h4>
                  <span className='role'>{image.role}</span>
              </div>
            </div>
          </div>
        ))}

        
      </div>

      {/* Render dots indicator */}
      <div className='dotss'>
        {images.map((image) => (
          <span
            key={image.id}
            // highlight the dot that corresponds to the current photo
            className={
              images[currentIndex].id === image.id ? 'dot1 active' : 'dot1'
            }
            // when the user clicks on a dot, go to the corresponding photo
            onClick={() => setCurrentIndex(images.indexOf(image))}
          ></span>
        ))}
        <div className='slides1-nav-holder'></div>
      </div>
      </div>
      </div>
    </>
  );
}

export default Testimonials;
