import React from 'react'
import './Mission.css'
import Time from '../assets/alarm.svg'
import Park from '../assets/park.svg'
import Volunteer from '../assets/volunteer.svg'
function Mission() {
  return (
    <div className='mission-section'>
    <div className='mission-div'>
        <div id='mission1' className='mission-icon-descp-div'>
            <img src={Time} alt='time' className='mission-icon'/>
            <h4 className='mission-h4'>Precise Estimates</h4>
            <p className='mission-para'>
            Delivering accurate project timelines and budgets, ensuring clarity and reliability from the start
            </p>
        </div>
        <div id='mission2' className='mission-icon-descp-div'>
            <img src={Park} alt='park' className='mission-icon'/>
            <h4 className='mission-h4'>Open Communication</h4>
            <p className='mission-para'>
            Maintaining transparent and consistent communication to foster trust and collaboration
            </p>
        </div>
        <div id='mission3' className='mission-icon-descp-div'>
            <img src={Volunteer} alt='' className='mission-icon'/>
            <h4 className='mission-h4'>Careful Craftsmanship</h4>
            <p className='mission-para'>
            Meticulously crafting designs with attention to detail, ensuring high-quality and user-centered outcomes.
            </p>
        </div>

    </div>


    </div>

  )
}

export default Mission