// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Agam from "./components/Agam";
import Wission from "./components/Wission";
import Walkfin from "./components/Walkfin";

import Privacy from "./components/Privacy";

import WalkfinUser from "./components/WalkfinUser";
function App() {
  return (
    <Router basename="/">

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<Privacy />} />

        <Route path="/agam-case-study" element={<Agam />} />
        <Route path="/wission" element={<Wission />} />
        <Route path="/walkfin-admin-case-study" element={<Walkfin />} />
        <Route path="/walkfin-user-case-study" element={<WalkfinUser />} />



      </Routes>
    </Router>
  );
}

export default App;