import React, { useEffect } from 'react';
import { Link as ScrollLink, scrollSpy } from 'react-scroll';
import Walkfinimg from '../assets/walkfin-admin.png';
import './ContactCaseStudy.css'
import './Walkfin.css'
import SEO from './SEO'

import Wireframeimg from '../assets/wireframe-walkfin.png'
import hexcode1 from '../assets/hex1-walkfinadmin.png'
import hexcode2 from '../assets/hex2-walkfinadmin.png'
import fontstyle1 from '../assets/style-walkfinadmin.png'

import fontstyle from '../assets/fontstyle-walkfin.png'
import pc1 from '../assets/pc1-walkfin.png'
import pc2 from '../assets/pc2-walkfin.png'
import pc3 from '../assets/pc3-walkfin.png'
import pc4 from '../assets/pc4-walkfin.png'
import pc5 from '../assets/pc5-walkfin.png'
import Previous from './Previous';
import ContactCaseStudy from './ContactCaseStudy';
import CaseStudyNavbar from './CaseStudyNavbar';
function Walkfin() {
  useEffect(() => {
    // Initialize scrollSpy
    scrollSpy.update();
  }, []);
  
  return (
    <div >
     <SEO
        title="Walkfin Admin Case Study - Commonsans"
        description="Walkfin is a new-age fintech company focused on providing stability, freedom, and growth opportunities to MSME entrepreneurs and students. They offer tailored lending options that empower both businesses and students."
        keywords="fintech, web design, Walkfin, UI/UX, case study"
        canonical="https://www.commonsans.studio/walkfin-admin-case-study"
      />
     <CaseStudyNavbar />
     <br></br><br></br>
    <div id="walkfin-section">
    
      <div className='main-wrapper'>
        <div className='nav-main-div'>
          <div className='nav-wrapper'>
            <ul id='navbarMenu'>
              <li className='cs-sidenav-link w-inline-block'>
                <ScrollLink
                  className='side-nav'
                  to='about-container-sec'
                  spy={true}
                  smooth="true"
                  duration={500}
                >
                  ABOUT
                </ScrollLink>
              </li>
              <li className='cs-sidenav-link w-inline-block '>
                <ScrollLink
                  className='side-nav'
                  to='discover-container-sec'
                  spy={true}
                  smooth="true"
                  duration={500}
                >
                  DISCOVER
                </ScrollLink>
              </li>
              <li className='cs-sidenav-link w-inline-block'>
                <ScrollLink
                  className='side-nav'
                  to='ideate-container-sec'
                  spy={true}
                  smooth="true"
                  duration={500}
                >
                  IDEATE
                </ScrollLink>
              </li>
              <li className='cs-sidenav-link w-inline-block '>
                <ScrollLink
                  className='side-nav'
                  to='efficiency-container-sec'
                  spy={true}
                  smooth="true"
                  duration={500}
                >
                  WIREFRAME
                </ScrollLink>
              </li>
              <li className='cs-sidenav-link w-inline-block'>
                <ScrollLink
                  className='side-nav'
                  to='enhanced-container-sec'
                  spy={true}
                  smooth="true"
                  duration={500}
                >
                  VISUAL DESIGN
                </ScrollLink>
              </li>
            </ul>
          </div>
        </div>
        <div className='wrapper1'>
    <div id='about-container-sec'className="wrapper1">
    <div className="item1">
          <div className='img-title-card1'>
            <img src={Walkfinimg} alt='' className='walkfin'/>
          </div>
        </div>
        <div className='ul-section'>
          <ul className='ul-walkfins'>
            <li>Web Design</li>
            <li>Fin tech</li>
          </ul>
        </div>
      </div>
      <div id='discover-container-sec'className="wrapper1">
        <div className="item1">
          <div className='img-title-card1'>
            <h4 className='card-title1'>About Walkfin</h4>
            <p className='card-title-p'>
            Walkfin is a new-age fintech company focused on providing stability, freedom, and
             growth opportunities to MSME entrepreneurs and students. They offer 
            tailored lending options that empower both businesses and students
                </p>
          </div>
          <div className='img-title-card2'>
            <h4 className='card-title1'>Problem Brief</h4>
            <p className='card-title-p'>
            Walkfin operates on the philosophy that time is money, and hence they are 
            looking to make the 
            loan approval and disbursal process digital for admins.
                </p>
          </div>
          <div className='img-title-cards3'>
            <h4 className='card-title1'>Wireframe</h4>
            <p className='card-title-p'>
            Keeping the admin tasks and work flow in mind, we ideated on how to 
            best create an interface that meets business
             objectives and is user-friendly for the admin.
                </p>
          </div>
          <img src={Wireframeimg} alt='' className='wireframeimg'/>

        </div>
   </div>
      <div id='ideate-container-sec' className="wrapper1">
        <div className="item1">
          <div className='img-titles-card4'>
          <h4 className='card-title1'>Visual Design</h4>
            <p className='card-title-p'>
            <div className='primary'><p><span className='colors'>Primary Color</span> The color of the application, purple is derived from the brand value
             representing wealth, royalty & independence.</p></div>
             <div className='secondary'><p><span className='colors'>Secondary Accent Color</span> Other than brand colors we have used red, orange, 
and green to indicate different statuses for approval and registration</p> </div>
</p>
          </div>
        </div>      
        </div>
        <div id='ideate1-container-sec' className="wrapper1">
        <div className="item1">
        <div className='font'>
          <img src={hexcode1} alt='' className='hexcode1'/>
          </div>
        
        <div className='hexcodes'>
          <img src={hexcode2} alt='' className='hexcode2'/>
          </div></div></div>
      <div id='wireframe-container-sec' className="wrapper1">
        <div className="item1">
          <div className='img-title-card-hex'>
          <img src={fontstyle} alt='' className='fontstyle'/>
          <img src={fontstyle1} alt='' className='fontstyle1'/>

            <p className='card-title-p1'>
            Montserrat is a clean, modern sans-serif font that
             enhances readability, making it easier for administrators to
             quickly scan and process information in the admin app.
</p>

          </div>
        </div>
      </div>
 
      
      <div id='efficiency-container-sec' className="wrapper1">
      <div className="item1">
        <h4 className='card-title1-pc1'>Improved Efficiency </h4>
          <div className='img-title-card-pc1'>
            <p className='card-title-pc1'>
            The dashboard with a <span className='span-style'> color coded pictorial 
           representation</span> made it easier 
            for admins to get a clear overview of data
<br></br><br></br>
The overview significantly <span className='span-style'>reduced the error</span> rate in 
interpretation and 
<span className='span-style'>increased</span> the <span className='span-style'>rate of loan</span> processed by <span className='span-style'> 32%</span>
</p>
          <img src={pc1} alt='' className='pc1'/>

          </div>
        </div>
      </div>
      <div id='enhanced-container-sec' className="wrapper1">
      <div className="item1">
          <div className='img-title-cards-pc1'>
       
          <img src={pc2} alt='' className='pc2'/>
          <div>  <h4 className='card-title1-pc2'>System visibility</h4>
          <p className='card-title-pc1'>
            The Heuristic - <span className='span-style'>Visibility of system status,</span> is followed throughout the app, 
            to communicate to the admins the<span className='span-style'> status of process</span>
</p></div> 
          </div>
          <div className='img-title-card-pc3'>
      <div>    <h4 className='card-title1-pc2'>Error Reduction</h4>

            <p className='card-title-pc3'>
            Clear statuses resulted in a drastic reduction in errors and a <span className='span-style'>76%
             decrease</span> in support requests, 
            leading to significant <span className='span-style'>reductions in administrative costs</span>
</p></div>          <img src={pc3} alt='' className='pc3'/>


          </div>
        </div>
      </div>
      <div id='workflow-container-sec' className="wrapper1">
      <div className="item1">
          <div className='img-title-card-pc4'>
          <img src={pc4} alt='' className='pc4'/>

         <div><h4 className='card-title1-pc4'>Enhanced Workflow efficiency </h4>
          <p className='card-title-pc4'>
            Clearly distinguished and <span className='span-style'>color-coded payment statuses </span> 
            improved<span className='span-style'> workflow efficiency</span>
             by <span className='span-style'>18%</span> as admins 
            were able to identify payment statuses effortlessly.
</p></div> 

          </div>
          <div className='img-title-card-pc5'>
         <div> <h4 className='card-title1-pc2'>Efficient Filtering</h4>
            <p className='card-title-pc5'>
            The well defined and <span className='span-style'>detailed filters </span> options admins could easily get 
            the required customer list
             reducing task completion time by 32%
</p>
</div>          <img src={pc5} alt='' className='pc5'/>

          </div>
        </div>
      </div>
      <div id='achieved-container-sec' className="wrapper1">
      <div className="item1">
      <div className='img-title-card-achieved'>
        <h4 className='card-title1-achieved'>What we achieved </h4>
            <p className='card-title-achieved'>
            Through the admin app, Walkfin was able to meet their
             business goals, save time, optimize workflow, and enhance efficiency, 
            leading to substantial cost savings.
</p>

          </div>
          
        </div>
      </div>
      <Previous 
        prevLink="/walkfin-user-case-study" 
        prevText="Walkfin User " 
        nextLink="/agam-case-study" 
        nextText="Agam Edu" 
      />
      </div>      
      </div>
      <div className="embedded-contact-form">
      <ContactCaseStudy />
      </div>
    </div></div>
  );
}

export default Walkfin;
