import React, { useState } from 'react';
import './Footer.css';
import linkfill from '../assets/linkfill.svg';
import logo from '../assets/logo-commansans.svg';
import { HashLink as Link } from 'react-router-hash-link';
import { Modal } from 'react-bootstrap';
import ContactForm from './ContactForm';

function Footer() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className='footer-section-mobile mobile'>
      <div className="footer-grid">
        <div id="footer1" className="footer-left-clm">
          <div className="footer-logo-holder">
            <img src={logo} alt="" className="footer-icon" />
          </div>
          <div className="footer-tag-text">
            <h4 className="footer-tag-text1">
              Have a project in mind? 
              <span className="expand-icon">
                <img src={linkfill} onClick={handleShow} alt='' className="link-expand-icon" />
              </span>
              <Modal show={show} onHide={handleClose}>
                <ContactForm />
              </Modal>
            </h4>
          </div>
        </div>
        <div id="footer2" className="footer-right-clm">
          <div id="footer-2" className="footer-address-div">
            <div className='ouraddress-txt'>Our Address</div>
            <div className='address-txt'>
              L-139, Baskar's Plaza, Second Floor, 15th Cross, 5th Main Rd, 6th Sector, HSR Layout, Bengaluru, Karnataka 560102
            </div>
          </div>
          <div id='footer3' className='footer-links-div' smooth>
            <a href="/" className="footer-text-link active1" smooth>Home</a>
            <Link to="/#services" className="footer-text-link" smooth>Services</Link>
            <Link to="/#case-studies" className="footer-text-link" smooth>Case Studies</Link>
            <Link to="/#about-us" className="footer-text-link" smooth>About</Link>
            <Link to="/privacy-policy" className="footer-text-link">Privacy Policy</Link>
          </div>
        </div>
        <div id="footer4" className="footer-copyrights-div">
          <div className="footer-copyright-text">
            Copyright 2022 Common Sans. All rights reserved
          </div>
        </div>
      </div>
      <div className="ellipse-holder-div">
        <div className="ellipse-div footer"></div>
      </div>
    </div>
  );
}

export default Footer;
