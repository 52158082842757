import React from 'react'
import './Icon.css'
import icon1 from '../assets/icon1.png'
import icon2 from '../assets/icon2.png'
import icon3 from '../assets/icon3.png'
import icon4 from '../assets/icon4.png'
import icon5 from '../assets/icon5.png'
import icon6 from '../assets/icon6.png'

function Icon() {
    return (
        <div className='icons-box'>
      <div className="icon-box-section1">
        <div className="icon-box">
        <img src={icon1} alt='' className='icons'/>
          <p className='icon-p'>Difficulty in extracting insights from current data charts</p>
        </div>
        <div className="icon-box">
        <img src={icon2} alt='' className='icons'/>
          <p className='icon-p'>Lack of ability to filter data for specific durations</p>
        </div>
        <div className="icon-box">
        <img src={icon3} alt='' className='icons'/>
          <p className='icon-p'>Lack of visibility of payment status of students</p>
        </div>
       
      </div>
      <div className="icon-box-section2">
        <div className="icon-box">
        <img src={icon4} alt='' className='icons'/>
          <p className='icon-p'>Limited visibility of lead status through the sales pipeline</p>
        </div>
        <div className="icon-box">
        <img src={icon5} alt='' className='icons'/>
          <p className='icon-p'>Challenges in accessing data at various levels (e.g., city centers)</p>
        </div>
        <div className="icon-box">
        <img src={icon6} alt='' className='icons'/>
          <p className='icon-p'>Overdue payments are not highlighted or notified</p>
        </div>
       
      </div>
      </div>
    );
  }
  
  

export default Icon