
import React, { useState } from 'react'
import './Tab.css'
import imgcard1 from '../assets/icons/uxaudit.svg'
import imgcard2 from '../assets/icons/usability.svg'
import imgcard3 from '../assets/icons/uxresearch.svg'
import imgcard4 from '../assets/icons/mobile&web.svg'
import imgcard5 from '../assets/icons/interaction.svg'
import imgcard6 from '../assets/icons/brandidentity.svg'
import imgcard7 from '../assets/icons/illustration.svg'
import imgcard8 from '../assets/icons/voiceover.svg'
import imgcard9 from '../assets/icons/convointerfaces.svg'
import imgcard10 from '../assets/icons/VR.svg'
import imgcard11 from '../assets/icons/AR.svg'
import arrow from '../assets/Vector.svg'
import { Modal } from 'react-bootstrap';
import ContactForm from './ContactForm';

function Tab() {
const [state,setState] = useState(1);

const action = (index) => {
setState(index)
}
const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <><div className='block-tabs'>
          <div className='tabs'>
          <div onClick={()=>action(1)} className={`${state===1 ?
  'tab active-tab':'tab'}`}>
  Strategy
</div>
           <div className={`${state===2 ? 'tab active-tab':'tab'}`} onClick={()=>action(2)}>
  Design
</div>
            <div className={`${state===3 ? 'tab active-tab':'tab'}`} onClick={()=>action(3)}>
  Technology
</div>
          </div>
        <div className='contents'>
            <div className={`${state===1 ?
             'content active-content':'content'}`}>
                 <div className="wrapper">
      <div className="item">
      <div className='img-title-card'>
      <img src={imgcard1} alt='' className='imgcard1'/>
      <h4 className='card-title'>Design Audit</h4>
      </div>
      <div className='card-btn'>
        <p className='card-content'>
        We help spot gaps in the experience of your Digital product, and provide
        solutions that is tailored to your business goals!
        </p>
        <div className='reachus-btn'>
            <button className='reachus'  onClick={handleShow}>REACH US</button>
            <img src={arrow} alt='' className='arrow'/>
            <Modal show={show} onHide={handleClose}>
      <ContactForm />
      </Modal>
</div>
        </div>
      </div>

      <div className="item">
      <div className='img-title-card'>
      <img src={imgcard2} alt='' className='imgcard2'/>
      <h4 className='card-title'>Usability Testing</h4>
      </div>
      <div className='card-btn'>
        <p className='card-content'>
        Bad usability is a lose-lose situation! That’s why we help evaluate the usability of your 
        digital product and provide a detailed usability report.
        </p>
        <div className='reachus-btn'>
        <button className='reachus'  onClick={handleShow}>REACH US</button>
            <img src={arrow} alt='' className='arrow'/>
            <Modal show={show} onHide={handleClose}>
      <ContactForm />
      </Modal>
        </div>
      </div>
      
      </div>
      <div className="item">
      <div className='img-title-card'>
      <img src={imgcard3} alt='' className='imgcard3'/>
      <h4 className='card-title'>User Research</h4>
      </div>
      <div className='card-btn'>
        <p className='card-content'>
        Through various research methods we understand the end-user and analyze their needs to create an experience
         that is not only effortless but also delightful!
        </p>
        <div className='reachus-btn'>
        <button className='reachus'  onClick={handleShow}>REACH US</button>
            <img src={arrow} alt='' className='arrow'/>
            <Modal show={show} onHide={handleClose}>
      <ContactForm />
      </Modal>
</div>
        </div>
      </div>
    </div>
            </div>
            <div className={`${state===2 ?
             'content active-content':'content'}`}>
                 <div className="wrapper">
      <div className="item">
      <div className='img-title-card'>
      <img src={imgcard4} alt='' className='imgcard4'/>
      <h4 className='card-title'>Web/Mobile UX UI Design</h4>
      </div>
      <div className='card-btn'>
        <p className='card-content'>
        Bad usability is a lose-lose situation! That’s why we help evaluate the usability of your 
        digital product and provide a detailed usability report.
        </p>
        <div className='reachus-btn'>
        <button className='reachus'  onClick={handleShow}>REACH US</button>
            <img src={arrow} alt='' className='arrow'/>
            <Modal show={show} onHide={handleClose}>
      <ContactForm />
      </Modal>
        </div>
      </div>
      
      </div>
      <div className="item">
      <div className='img-title-card'>
      <img src={imgcard5} alt='' className='imgcard5'/>
      <h4 className='card-title'>Interaction Design</h4>
      </div>
      <div className='card-btn'>
        <p className='card-content'>
        Enhance the behaviour of your product so that 
        your user can accomplish the goals without many 
        steps or much conscious effort
        </p>
        <div className='reachus-btn'>
        <button className='reachus'  onClick={handleShow}>REACH US</button>
            <img src={arrow} alt='' className='arrow'/>
            <Modal show={show} onHide={handleClose}>
      <ContactForm />
      </Modal>
        </div>
      </div>
      </div>
      <div className="item">
      <div className='img-title-card'>
      <img src={imgcard6} alt='' className='imgcard6'/>
      <h4 className='card-title'>Branding</h4>
      </div>
      <div className='card-btn'>
        <p className='card-content'>
        A strong brand identity is “Money”. Without 
        good branding, You’ll never be recognizable and
         convey the value of your company.
        </p>
        <div className='reachus-btn'>
        <button className='reachus'  onClick={handleShow}>REACH US</button>
            <img src={arrow} alt='' className='arrow'/>
            <Modal show={show} onHide={handleClose}>
      <ContactForm />
      </Modal>

        </div>
      </div>
      </div>
      <div className="item">
      <div className='img-title-card'>
      <img src={imgcard7} alt='' className='imgcard7'/>
      <h4 className='card-title'>Illustration & Iconography</h4>
      </div>
      <div className='card-btn'>
        <p className='card-content'>
        As we all know, the first impression 
        is the best! Vibrant Illustrations and consistent
         icons create a good impression so the customer
          will remember.
        </p>
        <div className='reachus-btn'>
        <button className='reachus'  onClick={handleShow}>REACH US</button>
            <img src={arrow} alt='' className='arrow'/>
            <Modal show={show} onHide={handleClose}>
      <ContactForm />
      </Modal>

        </div>
      </div>
      </div>
    </div>
            </div>
            <div className={`${state===3 ?
             'content active-content':'content'}`}>
                 <div className="wrapper">
      <div className="item">
      <div className='img-title-card'>
      <img src={imgcard8} alt='' className='imgcard8'/>
      <h4 className='card-title'>Voice User Interface</h4>
      </div>
      <div className='card-btn'>
        <p className='card-content'>
        A fast growing technology that makes human
         computer interaction possible. We help your
          business to create workflows over frameworks
           like Alexa skills.
        </p>
        <div className='reachus-btn'>
        <button className='reachus'  onClick={handleShow}>REACH US</button>
            <img src={arrow} alt='' className='arrow'/>
            <Modal show={show} onHide={handleClose}>
      <ContactForm />
      </Modal>

        </div>
      </div>
      </div>
      <div className="item">
      <div className='img-title-card'>
      <img src={imgcard9} alt='' className='imgcard9'/>
      <h4 className='card-title'>Conversational Interfaces</h4>
      </div>
      <div className='card-btn'>
        <p className='card-content'>
        Queries over call and filling digital forms 
        takes a lot of time? We help you design chatbot 
        for your business to reduce your work and
         increase your time!
        </p>
        <div className='reachus-btn'>
        <button className='reachus'  onClick={handleShow}>REACH US</button>
            <img src={arrow} alt='' className='arrow'/>
            <Modal show={show} onHide={handleClose}>
      <ContactForm />
      </Modal>
        </div>
      </div>
      </div>
      <div className="item">
      <div className='img-title-card'>
      <img src={imgcard10} alt='' className='imgcard10'/>
      <h4 className='card-title'>VR UX/UI Design</h4>
      </div>
      <div className='card-btn'>
        <p className='card-content'>
        We have extensive experience on mutating digital
         experience to virtual reality. If VR brings value
          to your business then you are in the right place.
        </p>
        <div className='reachus-btn'>
        <button className='reachus'  onClick={handleShow}>REACH US</button>
            <img src={arrow} alt='' className='arrow'/>
            <Modal show={show} onHide={handleClose}>
      <ContactForm />
      </Modal>

        </div>
      </div>
      </div>
      <div className="item">
      <div className='img-title-card'>
      <img src={imgcard11} alt='' className='imgcard11'/>
      <h4 className='card-title'>AR UX /UI Design</h4>
      </div>
      <div className='card-btn'>
        <p className='card-content'>
        Turn your ideas into an interactive/immersive 
        experiences that combines virtual information 
        with the real world.
        </p>
        <div className='reachus-btn'>
        <button className='reachus'  onClick={handleShow}>REACH US</button>
            <img src={arrow} alt='' className='arrow'/>
            <Modal show={show} onHide={handleClose}>
      <ContactForm />
      </Modal>

        </div>
      </div>
      </div>
    </div>
            </div>
        </div>
          </div></>

  )
}

export default Tab
