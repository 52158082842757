import React, { useState } from 'react';
import './Navbar.css';
import logo from '../assets/logo-commansans.svg';
import { Modal } from 'react-bootstrap';
import ContactForm from './ContactForm';
import { HashLink as Link } from 'react-router-hash-link';

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleClose = () => {
    setClicked(false);
    setShow(false);
  };

  const handleShow = () => setShow(true);

  return (
    <div className='sticky-nav mobile w-nav'>
      <nav id="nav-div" className='navbarItems'>
      <Link to="/">
      <img src={logo} alt='Logo' className='logo' /></Link>
        <div className='menu-icons' onClick={handleClick}>
          <i className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
        <ul id='navbarMenu' className={clicked ? 'nav-menu active1' : 'nav-menu'}>
   <li className='nav-links'>
            <Link className='active1' to='/' smooth="true" onClick={handleClick}>
              Home
            </Link>
          </li>
          <li className='nav-links'>
            <Link to='/#services' smooth="true" onClick={handleClick}>
              Services
            </Link>
          </li>
          <li className='nav-links'>
            <Link to='/#case-studies' smooth="true" onClick={handleClick}>
              Case Studies
            </Link>
          </li>
          <li className='nav-links'>
            <Link to='#about-us' smooth="true" onClick={handleClick}>
              About
            </Link>
          </li>
          <li className='nav-links privacy'>
            <Link to='/privacy-policy' smooth="true" onClick={handleClick}>
              Privacy Policy
            </Link>
          </li>
        </ul>
        <button className='navDesktopMenuBtn' onClick={handleShow}>
          Contact Us
        </button>
        <Modal show={show} onHide={handleClose}>
          <ContactForm />
        </Modal>
      </nav>
    </div>
  );
};

export default Navbar;
