import { useState, useEffect, useRef } from 'react';
import './Carousel.css';
import { useNavigate } from 'react-router-dom';  // Use useNavigate for navigation
import scrolling from '../assets/scrolling.png';

const photosData = [
  {
    id: 'p1',
    title: 'Photo One',
    className: 'Agam photo',
    url: import('../assets/Cs/Group 427322298.png'),
    link: '/agam-case-study',
  },
  {
    id: 'p2',
    title: 'Photo Two',
    className: 'walkfinuser photo',
    url: import('../assets/Cs/Walkfin User.png'),
    link: '/walkfin-user-case-study',
  },
  {
    id: 'p3',
    title: 'Photo Three',
    className: 'walkfinsimg photo',
    url: import('../assets/Cs/Walkfin Admin.png'),
    link: '/walkfin-admin-case-study',
  },
];

function Carousel() {
  const [photos, setPhotos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Store touch start position and time
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const navigate = useNavigate();  // To handle navigation

  useEffect(() => {
    // Load all image URLs asynchronously and update the state
    Promise.all(photosData.map((photo) => photo.url))
      .then((imageURLs) => {
        const photosWithResolvedURLs = photosData.map((photo, index) => ({
          ...photo,
          url: imageURLs[index].default,
        }));
        setPhotos(photosWithResolvedURLs);
      })
      .catch((error) => {
        console.error('Error loading image URLs:', error);
      });
  }, []);

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === photos.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1
    );
  };

  // Handle touch start event (for swiping)
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  // Handle touch move event (for swiping)
  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  // Handle touch end event to determine swipe action
  const handleTouchEnd = () => {
    const swipeDistance = touchStartX.current - touchEndX.current;

    const swipeDistanceThreshold = 50; // Minimum swipe distance for slide transition

    if (swipeDistance > swipeDistanceThreshold) {
      // Swipe left (next slide)
      goToNextSlide();
    } else if (swipeDistance < -swipeDistanceThreshold) {
      // Swipe right (previous slide)
      goToPreviousSlide();
    }
  };

  // Handle click to navigate to the photo link
  const handleClick = (photo) => {
    navigate(photo.link);  // Navigate to the corresponding link on click
  };

  return (
    <>
      <div className='slider'>
        <div className='scrolling-circle'>
          <img src={scrolling} alt='' className='scrolling' />
        </div>

        <div className='photo-container'>
          <div className='slider-container'>
            {photos.map((photo) => (
              <div
                key={photo.id}
                className={
                  photos[currentIndex].id === photo.id ? 'fade' : 'slide fade'
                }
                onTouchStart={handleTouchStart}  // Handle swipe start
                onTouchMove={handleTouchMove}    // Handle swipe move
                onTouchEnd={handleTouchEnd}      // Handle swipe end
                onClick={() => handleClick(photo)}  // Handle click/tap to navigate
              >
                <img src={photo.url} alt={photo.title} className={photo.className} />
                <div className='caption'>{photo.title}</div>
              </div>
            ))}
          </div>

          <div className='dots-container'>
            <div className='dots'>
              {photos.map((photo) => (
                <span
                  key={photo.id}
                  className={
                    photos[currentIndex].id === photo.id ? 'dot active2' : 'dot'
                  }
                  onClick={() => setCurrentIndex(photos.indexOf(photo))}
                ></span>
              ))}
              <div className='slides-nav-holder'></div>
            </div>
          </div>
        </div>

        <div className='ellipse-holder-div-cs'>
          <div className='ellipse-div case-study'></div>
        </div>
      </div>
    </>
  );
}

export default Carousel;
